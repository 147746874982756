import axios from 'axios';
import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import { MODULES_NAME } from './modules';

/**
 * Check registred module
 * @param {Array} aPath - path to module - ex: ['my', 'nested', 'module']
 * @return {Boolean}
 */
Vuex.Store.prototype.hasModule = function hasModule(aPath) {
  // eslint-disable-next-line no-underscore-dangle
  let m = this._modules.root;
  return aPath.every((p) => {
    // eslint-disable-next-line no-underscore-dangle
    m = m._children[p];
    return m;
  });
};

/**
 * асинхронная загрузка модуля
 * @param name {string} - название модуля
 * @param lazyModule - импорт модуля import('...')
 * @returns {Promise<void>}
 */
Vuex.Store.prototype.loadingVuexModule = async function loadingVuexModule({
  name,
  lazyModule,
}) {
  try {
    if (!this.hasModule([name])) {
      const module = await lazyModule;
      // исключаем повторную лишнюю регистрацию модуля после загрузки
      if (!this.hasModule([name])) {
        this.registerModule(name, module.default, { preserveState: !!this.state[name] });
      }
    }
  } catch (e) {
    throw e;
  }
};

/**
 * запуск экшена у асинхронного модуля, если модуля нет, подключает его перед запуском экшена.
 * @param moduleName {string} - название модуля в сторе
 * @param dispatchName {string} - название экшена
 * @param payload {any} - полезная нагрузка для экшена
 * @param options {object} - опции передаваемые в dispatch
 * @param lazyModule {Promise} - функция импорта модуля
 * @returns {Promise<T|any>} - возвращает результат экшена
 */
Vuex.Store.prototype.lazyModuleDispatch = async function lazyModuleDispatch({
  moduleName, dispatchName, payload, options, lazyModule,
}) {
  try {
    await this.loadingVuexModule({ name: moduleName, lazyModule });
    const res = await this.dispatch(`${moduleName}/${dispatchName}`, payload, options);
    return res;
  } catch (e) {
    throw e;
  }
};

Vue.use(Vuex);

const storeVisuallyImpaired = JSON.parse(window.sessionStorage.getItem('VisuallyImpaired'));
const VisuallyImpaired = {
  fontSize: {
    factor: 1,
    default: parseInt(window.getComputedStyle(document.documentElement).fontSize, 10),
  },
  themes: {
    options: {
      default: {
        value: [],
        name: 'Стандартная',
      },
    },
    value: 'default',
  },
  images: {
    options: {
      default: {
        value: [],
        name: 'Стандартные',
      },
    },
    value: 'default',
  },
};
if (storeVisuallyImpaired && storeVisuallyImpaired.fontSize) {
  VisuallyImpaired.fontSize = {
    ...VisuallyImpaired.fontSize,
    ...storeVisuallyImpaired.fontSize,
  };
}
if (storeVisuallyImpaired && storeVisuallyImpaired.themes) {
  VisuallyImpaired.themes = {
    ...VisuallyImpaired.themes,
    ...storeVisuallyImpaired.themes,
  };
}
if (storeVisuallyImpaired && storeVisuallyImpaired.images) {
  VisuallyImpaired.images = {
    ...VisuallyImpaired.images,
    ...storeVisuallyImpaired.images,
  };
}
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    loadingData: false,
    notifies: [],
    manager: {},
    events: [],
    agents: [],
    event: {},
    vacancyId: null,
    requestId: null,
    callbackTimeId: null,
    regionId: null,
    activeTab: window.GELIOS.shouldRegister ? 'register' : '',
    isMessage: false,
    apiDomain: window.GELIOS.apiDomain,
    message: {
      header: '',
      body: '',
    },
    isModalVisible: false,
    modalComponent: window.GELIOS.isSearchActive ? 'search' : 'auth',
    modalTitle: '',
    modalParams: {},
    regions: window.GELIOS.regions ? window.GELIOS.regions : [],
    activeCity: window.GELIOS.activeCity ? window.GELIOS.activeCity : {},
    activePolicyId: null,
    presenceCities: [],
    callbackTimes: [],
    feedbackTopics: [],
    requestProducts: [],
    requestRegions: [],
    searchQuery: '',
    searchResults: [],
    hasSearch: false,
    popup: {
      open: false,
      component: null,
    },
    VisuallyImpaired,
  },
  getters: {
    activeRegion(state) {
      return state.regions.filter(region => region.isActive)[0];
    },
    feedbackTopicId(state) {
      if (!state.feedbackTopics.length) return '';
      const activeTopic = state.feedbackTopics.filter(group => group.options.filter(el => el.isActive).length);
      if (activeTopic.length) {
        return activeTopic[0].options[0].value;
      }
      return '';
    },
    stylesTheme(state) {
      return state.VisuallyImpaired.themes.options[state.VisuallyImpaired.themes.value].value;
    },
    stylesImagesFilter(state) {
      return state.VisuallyImpaired.images.options[state.VisuallyImpaired.images.value].value;
    },
    fontSizeFactor(state) {
      return state.VisuallyImpaired.fontSize.factor;
    },

  },
  actions: {
    makeAPICall({ dispatch, commit, state }, {
      endpoint, payload, method, shouldAuth,
    }) {
      const data = {
        url: encodeURI(endpoint),
        method,
        data: payload,
      };
      return new Promise((resolve, reject) => {
        axios({
          url: `${state.apiDomain}`,
          method: 'post',
          headers: {
            'content-type': 'application/json',
            ...(shouldAuth ? { 'X-auth-token': state.token } : {}),
          },
          data,
        })
          .then((resp) => {
            if (resp.data.status) {
              resolve(resp.data);
            } else if (resp.data.result) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            commit('showMessage', `${err.response.status} ${err.response.statusText}`);
            reject();
          });
      });
    },
    getAgents({ commit }, searchQuery) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ajax/agents.php', {
            params: {
              queryS: searchQuery,
            },
          })
          .then((response) => {
            if (response.data.rows.length === 0) {
              document.getElementsByClassName('errorSearch')[0].style.display = 'block';
              commit('unsetAgentsData', response.data);
            } else {
              commit('setAgentsData', response.data);
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    makeSearch({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ajax/search.php', { params: formData })
          .then((response) => {
            commit('setSearchResults', response.data);
            commit('setSearchQuery', formData.q);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getFeedbackTopics({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ajax/submitFeedback.php', { params: { action: 'init' } })
          .then((response) => {
            commit('setFeedbackTopics', response.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCallbackData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ajax/submitCallback.php', { params: { action: 'init' } })
          .then((response) => {
            commit('setCallbackData', response.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getRequestProducts({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ajax/submitRequest.php', { params: { action: 'init' } })
          .then((response) => {
            commit('setRequestProducts', response.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getRequestRegions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ajax/submitSuperKaskoRequest.php', { params: { action: 'init' } })
          .then((response) => {
            commit('setRequestRegions', response.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPresenceCities({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ajax/getPresenceCities.php')
          .then((response) => {
            commit('setPresenceCities', response.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sendActiveCity({ commit, state }, city) {
      const prevActiveCity = state.activeCity;
      commit('setActiveCity', city);
      axios
        .post('/ajax/setActiveCity.php', city)
        .then((response) => {
          if (response.data.result) {
            commit('closeModal');
            window.location = window.location.href.split('?')[0];
          }
        })
        .catch((err) => {
          commit('setActiveCity', prevActiveCity);
        });
    },

  },
  mutations: {
    setNotifiesData(state, payload) {
      state.notifies = payload;
    },
    showMessage(state, { header, body }) {
      state.message = { header, body };
      state.isMessage = true;
    },
    hideMessage(state) {
      state.isMessage = false;
      state.message = { header: '', body: '' };
    },
    setPolicyId(state, payload) {
      state.activePolicyId = payload;
    },
    setManagerData(state, payload) {
      state.manager = payload;
    },
    setAgentsData(state, payload) {
      state.agents = payload;
    },
    unsetAgentsData(state, payload) {
      state.agents = '';
    },
    setPageLoadingState(state, payload) {
      state.loadingData = payload;
    },
    closeModal(state) {
      state.isModalVisible = false;
    },
    showModal(state, payload) {
      // if (payload.params) {
      //   this.pageData.extra = payload.params;
      // }
      if (state.modalTitle && (!payload.params || !payload.params.modalTitle)) {
        state.modalTitle = '';
      }
      if (state.modalParams && (!payload.params || !payload.params.modalParams)) {
        state.modalParams = '';
      }
      Object.assign(state, payload.params);
      state.modalComponent = payload.name;
      state.isModalVisible = true;
    },
    closePopup(state) {
      state.popup.open = false;
      state.popup.component = null;
    },
    showPopup(state, { component }) {
      state.popup.component = component;
      state.popup.open = true;
    },
    setActiveRegion(state, payload) {
      state.regions.forEach((elt) => {
        elt.isActive = elt.id === payload;
      });
    },
    setSearchResults(state, payload) {
      state.searchResults = payload.results;
      state.hasSearch = true;
    },
    resetSearchResults(state) {
      state.searchResults = [];
      state.hasSearch = false;
      state.searchQuery = '';
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload;
    },
    resetRequestId(state) {
      if (state.requestId) {
        state.requestId = null;
      }
    },
    setFeedbackTopics(state, payload) {
      state.feedbackTopics = payload.feedbackTopics;
    },
    setRequestProducts(state, payload) {
      state.requestProducts = payload.requestProducts;
    },
    setRequestRegions(state, payload) {
      state.requestRegions = payload.requestRegions;
    },
    setCallbackData(state, payload) {
      state.callbackTimes = payload.callbackTimes;
      state.regions = payload.regions;
    },
    setActiveCity(state, payload) {
      state.activeCity = payload;
    },
    setPresenceCities(state, payload) {
      state.presenceCities = payload;
    },
    updateVisuallyImpaired(state, payload) {
      state.VisuallyImpaired.fontSize = {
        ...state.VisuallyImpaired.fontSize,
        ...payload.fontSize,
      };
      state.VisuallyImpaired.themes = {
        ...state.VisuallyImpaired.themes,
        ...payload.themes,
      };
      state.VisuallyImpaired.images = {
        ...state.VisuallyImpaired.images,
        ...payload.images,
      };
      window.sessionStorage.setItem('VisuallyImpaired', JSON.stringify(payload));
      window.document.documentElement.style.fontSize = `${state.VisuallyImpaired.fontSize.default * state.VisuallyImpaired.fontSize.factor}px`;
    },
  },
  modules: {
    // модули загружаем асинхронно методами `loadingVuexModule` и `lazyModuleDispatch`
  },
  plugins: [createPersistedState({
    key: 'gelios',
    paths: [MODULES_NAME.AUTH],
  })],
});
